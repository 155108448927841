import React from 'react';
import './Serve.scss';
import Layout from '../../Components/Layout/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { PortableText } from '@portabletext/react';
/**
 * @namespace Serve
 * @function Serve
 * @author Trevor Cash
 * @since 10/21/21
 * @version 1.0.0
 * @component
 */
export default function Serve() {
  const data = useStaticQuery(graphql`
    query serveQuery {
      allSanityServe {
        nodes {
          image {
            asset {
              url
            }
          }
          _rawBody
        }
      }
      allSanityServing {
        nodes {
          title
          when
        }
      }
    }
  `);
  const image = data?.allSanityServe?.nodes?.[0]?.image?.asset?.url;
  const body = data?.allSanityServe?.nodes?.[0]?._rawBody;
  const serving = data?.allSanityServing?.nodes;
  return (
    <Layout
      className='Giving-Container'
      title='Serve'
      description='First Church of West Jefferson, Ohio can help you to find friends, connect with the community and make an impact supporting causes you care about. We care about exploring new ways to connect with the local community to help us amplify our giving initiatives.'
    >
      <h1>Serve</h1>
      <div className='Content'>
        <div className='Text'>{body && <PortableText value={body} />}</div>
        <div className='Image'>
          <img src={image} alt='Prayer Group' loading='lazy' />
        </div>
      </div>
      <div>
        <h2>Checkout our opportunities for serving!</h2>
        {serving?.map((opp, index) => (
          <div className='Small-Group' key={index}>
            <span>{opp.title}</span>
            {opp.when && <span>: {opp.when}</span>}
          </div>
        ))}
      </div>
    </Layout>
  );
}
